import { graphql } from "gatsby"
import React from "react"
import Pagination from "../components/common/pagination.component"
import {
  Category,
  Resource,
} from "../components/community/resources/resource.interface"
import ResourcesContent from "../components/community/resources/resources-content.component"
import ResourcesInfo from "../components/community/resources/resources-info.component"
import ResourcesList from "../components/community/resources/resources-list.component"
import Layout from "../components/layout"
import SEO from "../components/seo.component"
import { ContentType } from "../constants/Query"
import { useFilteredContent } from "../helpers/get-filtered-content"
import { AppFunctionComponent, QueryResult, TextBuilder } from "../types"
import { DetectedBreadcrumbs, TopNavigationWrapper } from "../navigation"
import { Tag as TagProps } from "../components/community/resources/resource.interface"
import PageMetadata from "../components/seo/page-metadata.interface"
import { Menu } from "../navigation/types/menu.interface"

interface Props {
  pageContext: {
    title: string
    slug: string
    categories: Category[]
    metaName: string
    resources: Resource[]
    totalPages: number
    basePath: string
    page: number
  }
  data: {
    tagContent: QueryResult<TagProps>
    meta: PageMetadata
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
  }
}

const ResourcesIndex: AppFunctionComponent<Props> = ({ pageContext, data }) => {
  const { title, categories, resources, totalPages, basePath, slug, page } =
    pageContext
  const {
    meta,
    tagContent,
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  } = data

  const { pending, items, pagination, queryParams } = useFilteredContent({
    contentType: ContentType.Resource,
    allItems: resources,
    totalPages,
    basePath,
    slug,
    page,
  })

  const normalizedPagination = {
    currentPage: page,
    pagePrefix: basePath,
    nextPage: page < totalPages ? page + 1 : null,
    prevPage: page === 1 ? null : page - 1,
  }

  const paginationComponent = (
    <Pagination
      current={page}
      rootUrl={pagination.basePath}
      total={pagination.totalPages}
      query={queryParams}
    />
  )

  return (
    <Layout
      topNavigationItems={topNavigationItems}
      footerNavigationItems={footerNavigationItems}
      promobarContent={promobarContent}
      masterPage
    >
      <SEO
        title={`Resources - ${title}`}
        {...meta}
        pagination={normalizedPagination}
      />
      <TopNavigationWrapper breadcrumbsMenu={<DetectedBreadcrumbs />} />
      <ResourcesInfo />
      <ResourcesContent
        tagContent={tagContent}
        categories={categories}
        basePath={basePath}
      >
        <ResourcesList
          tags={tagContent}
          resources={items}
          pagination={paginationComponent}
          pending={pending}
        />
      </ResourcesContent>
    </Layout>
  )
}

export default ResourcesIndex

export const query = graphql`
  query ResourcePageMetaQuery(
    $metaName: String!
    $language: String!
    $locale: String!
  ) {
    meta: contentfulPageMeta(page: { eq: $metaName }) {
      ...PageMeta
    }
    tagContent: allContentfulTag(
      filter: {
        type: { in: ["common", "resources"] }
        node_locale: { eq: $locale }
      }
    ) {
      edges {
        node {
          slug
          text
          type
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "63Ao7XUYTc7UtDipcRpwoc" }
      node_locale: { eq: $locale }
    ) {
      text {
        raw
      }
      node_locale
    }
  }
`
